<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="product.affiliate_stores"
      resource="/admin/ecommerce/affiliate-store"
      creatable
      @click-clear-filter="clearFilter"
      @click-create="showCreateModal = true"
      enterable
      removable
    >
      <template #name="{ item }">
        <td>
          <TMessageText :value="item.name" />
        </td>
      </template>
      <template #address="{ item }">
        <td class="text-break">
          <TMessageText :value="item.address" />
        </td>
      </template>
      <template #ecommerce_channel="{ item }">
        <td>
          <TMessageText
            :value="item.ecommerce_channel ? item.ecommerce_channel.name : ''"
          />
        </td>
      </template>
      <template #img_store="{ item }">
        <td class="text-break">
          <TMessageText
            :value="item.img_store"
            :messageOptions="{ truncate: 3 }"
          />
        </td>
      </template>
      <template #name-filter>
        <TInputText :value.sync="filter.name" @update:value="filterChange" />
      </template>

      <template #ecommerce_channel-filter>
        <SSelectProductEc
          prependAll
          :value.sync="filter.ecommerce_channel_id"
          @update:value="filterChange"
        />
      </template>
      <template #tel-filter>
        <TInputText :value.sync="filter.tel" @update:value="filterChange" />
      </template>
      <template #type-filter>
        <TInputText :value.sync="filter.type" @update:value="filterChange" />
      </template>
    </TTableAdvance>
    <AffiliateStoreModal :show.sync="showCreateModal" />
    <AffiliateStoreModal
      @updated="filterChange()"
      :show.sync="showEditModal"
      :value="detail"
    />
    <CategoryAffiliateModal
      @updated="reselectAS"
      :detail="detail"
      :show.sync="showEditCategoryModal"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AffiliateStoreModal from "./components/AffiliateStoreModal.vue";
import CategoryAffiliateModal from "./components/CategoryAffiliateModal.vue";

export default {
  components: { AffiliateStoreModal, CategoryAffiliateModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "name", label: "Name", _style: "min-width: 200px" },
        {
          key: "ecommerce_channel",
          label: "Ecommerce channel",
          _style: "min-width: 200px",
          _classes: "text-nowrap",
        },
        { key: "type", label: "Type", _style: "min-width: 200px" },

        { key: "tel", label: "Tel", _style: "min-width: 200px" },
        { key: "address", label: "Address", _style: "min-width: 300px" },
        {
          key: "img_store",
          label: "Featured image",
          _style: "min-width: 300px",
        },
      ],
      showCreateModal: false,
      showEditModal: false,
      showEditCategoryModal: false,
      filter: {},
    };
  },
  created() {
    this.$store.dispatch("product.affiliate_stores.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "product.affiliate_stores.list",
      detail: "product.affiliate_stores.detail",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy({
        ...filter,
      });
      return this.$store.dispatch(
        "product.affiliate_stores.apply-query",
        filterFields
      );
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
    removeItem(id) {
      this.$store.dispatch("product.affiliate_stores.delete", id);
    },
    editItem(item) {
      this.$store.commit("product.affiliate_stores.select", item.id);
      this.showEditModal = true;
    },
    editCatogory(item) {
      this.$store.commit("product.affiliate_stores.select", item.id);
      this.showEditCategoryModal = true;
    },
    reselectAS() {
      this.filterChange().then(() => {
        this.$store.commit("product.affiliate_stores.select", this.detail.id);
      });
    },
  },
};
</script>

<template>
  <TModal
    :title="is_edit ? 'Update' : 'Create affiliate store'"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :key="key"
    :creating="loading"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
        v-if="!is_edit"
      />
      <TButtonSave
        variant="outline"
        @click="update"
        v-if="is_edit"
        :options="{ disabled: loading }"
      />
    </template>
    <TInputText
      label="Name"
      class="mb-3"
      @update:value="data.name = $event"
      :value="input.name"
    />
    <SSelectProductEc
      label="Ecommerce channel"
      @update:value="data.ecommerce_channel_id = $event"
      class="mb-3"
      :value="input.ecommerce_channel_id"
      prependAll
      :prepend="{ id: '', name: $t('Select') }"
    />
    <TInputText
      label="Tel"
      class="mb-3"
      @update:value="data.tel = $event"
      :value="input.tel"
    />
    <TInputText
      label="Address"
      class="mb-3"
      @update:value="data.address = $event"
      :value="input.address"
    />
    <TInputText
      @update:value="data.img_store = $event"
      :value="input.img_store"
      label="Featured image"
      class="mb-3"
      placeholder="Enter image url"
      :inputOptions="{ prepend: 'URL' }"
    />
    <TInputText
      label="Type"
      class="mb-3"
      @update:value="data.type = $event"
      :value="input.type"
    />
    <TMessage content="Banner" noTranslate class="mb-2" bold />
    <TInputFile
      accept="image/*"
      :value.sync="album"
      :multiple="true"
      theme=""
      :maxFiles="5"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      require: false,
    },
  },
  inputDefault: {
    id: "",
    name: "",
    parent_id: "",
  },
  data() {
    return {
      loading: false,
      data: this.getInput(),
      key: "key",
      album: [],
    };
  },
  watch: {
    value() {
      this.data = this.getInput();
    },
  },
  computed: {
    is_edit() {
      if (!this.value) {
        return false;
      }
      return !!this.value.id;
    },
    input: {
      get: function () {
        return this.data;
      },
      set: function (newValue) {
        return (this.data = newValue);
      },
    },
  },
  methods: {
    create() {
      this.loading = true;
      let data = {
        ...this.input,
      };
      this.album.forEach((x, i) => {
        const item = {
          [`images[${i}][file]`]: x.file,
          [`images[${i}][type]`]: "primary",
        };

        data = { ...data, ...item };
      });
      this.$store
        .dispatch("product.affiliate_stores.create", data)
        .then(() => {
          this.input = this.inputDefault();
          this.$emit("update:show", false);
          this.$emit("updated", data);
          this.reset();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.$store
        .dispatch("product.affiliate_stores.detail.update", {
          ...this.lodash.pickBy(this.data),
        })
        .then((data) => {
          this.$emit("update:show", false);
          this.$emit("updated", data);
        });
    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.key = this.lodash.resetKey("key");
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      return { ...this.value };
    },
  },
};
</script>

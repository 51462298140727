<template>
  <TModal
    title="Category"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :creating="loading"
  >
    <template #actions>
      <div></div>
    </template>
    <SSelectProductCategory
      label="Category"
      :prepend="[{ id: '', name: $t('Select') }]"
      @update:value="category_id = $event"
      :value.sync="category_id"
      class="mb-3"
    />
    <TButton
      content="Add"
      icon="cil-plus"
      class="float-right mb-3"
      :options="{ disabled: !category_id }"
      @click="createCategoryAffiliate"
    />
    <TTableSimple :fields="itemFields" :items="detail.categories" noResult>
      <template #_="{ item }">
        <td>
          <TButtonRemove @click="removeCateAffi(item.id)" />
        </td>
      </template>
      <template #category="{ item }">
        <td>
          <SMessageRole :id="item.name" />
        </td>
      </template>
    </TTableSimple>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      loading: false,
      category_id: "",
      itemFields: [
        {
          key: "_",
          label: "#",
          _style: "width: 40px",
        },
        {
          key: "category",
          label: "Category",
          _style: "min-width: 150px",
          placeholder: "Description",
        },
      ],
    };
  },
  methods: {
    createCategoryAffiliate() {
      this.$store
        .dispatch("product.affiliate_stores.detail.create-category", {
          category_ids: [this.category_id],
        })
        .then(() => {
          this.category_id = "";
          this.$emit("updated");
        });
    },
    removeCateAffi(id) {
      this.$store
        .dispatch("product.affiliate_stores.detail.delete-category", id)
        .then((data) => {
          this.$emit("updated", data);
        });
    },
  },
};
</script>
